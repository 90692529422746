import { v4 as uuidv4 } from "uuid";
import { AdditionalItem } from "./openapi/transaction-api-v3";
import { BasketItemPayload, ErrorType, FulfillerResponse, ItemTokens, TokenGetter } from "./types";

export const populateRequestUdidToken = (itemTokens: ItemTokens): ItemTokens => {
  let modifiedItemTokens = { ...itemTokens };

  try {
    // Add requestUDID if it doesn't exist
    if (modifiedItemTokens && !modifiedItemTokens.requestUDID) {
      modifiedItemTokens = { ...modifiedItemTokens, requestUDID: uuidv4() };
    }
  } catch (e) {
    // TODO - replace with logging library once implemented
    console.log(`Failed to populate requestUdid: ${e as string}`);
    throw e;
  }

  return modifiedItemTokens;
};

export const typecastBasketItemPayloadFields = (item: BasketItemPayload): BasketItemPayload => {
  // Disabling this lint rule to prevent autofix of modifiedItem to const - const will be
  // incorrect since modifiedItem is modified below
  /* eslint-disable prefer-const */
  let modifiedItem = { ...item };
  let modifiedAdditionalItems: Array<AdditionalItem>;

  try {
    modifiedItem.quantity = Number(modifiedItem.quantity);
    modifiedItem.valueInPence = Number(modifiedItem.valueInPence);

    if (modifiedItem.additionalItems) {
      modifiedAdditionalItems = modifiedItem.additionalItems.map((additionalItem) => {
        return {
          ...additionalItem,
          quantity: Number(additionalItem.quantity),
          valueInPence: Number(additionalItem.valueInPence),
        };
      });

      modifiedItem.additionalItems = modifiedAdditionalItems;
    }
  } catch (e) {
    // TODO - replace with logging library once implemented
    console.log(`Failed to typecast basket item payload fields: ${e as string}`);
    throw e;
  }

  return modifiedItem;
};

export const isNetworkError = (error: unknown): boolean => {
  if (
    error === ErrorType.NetworkError ||
    (error instanceof Error && error.message.includes(ErrorType.NetworkError)) ||
    (typeof error === "string" && error.includes(ErrorType.NetworkError))
  ) {
    return true;
  }

  return false;
};

export const respond = (approved: boolean, response: FulfillerResponse): Promise<FulfillerResponse> => {
  return approved ? Promise.resolve(response) : Promise.reject(response);
};

export async function getToken(tokenGetter: TokenGetter): Promise<string> {
  if (typeof tokenGetter === "string") {
    return tokenGetter;
  }

  return tokenGetter();
}
